<template>
  <div class="md-layout-item md-size-100">
    <UnitsTable />
  </div>
</template>

<script>
export default {
  components: {
    UnitsTable: () => import("@/components/Tables/UnitsTable"),
  },
};
</script>
